<template>
  <CardToolbox>
    <sdBreadcrumb :routes="routes" />
    <sdPageHeader title="ข้อมูลนักเรียน">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="warning" key="1">
          <router-link :to="`/schoolYears/${schoolYearId}/classrooms/${classroomId}/`"
            >กลับไปหน้าห้องเรียนของฉัน</router-link
          >
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <AddUser>
          <sdCards>
            <template #title>
              <div class="card-nav">
                <ul>
                  <li>
                    <router-link :to="`info`">
                      <sdFeatherIcons type="user" size="14" />
                      ข้อมูลนักเรียน
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`development`">
                      <sdFeatherIcons type="star" size="14" />
                      พัฒนาการ
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`bodymass`">
                      <sdFeatherIcons type="trending-up" size="14" />
                      น้ำหนัก ส่วนสูง
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`attendance`">
                      <sdFeatherIcons type="clock" size="14" />
                      มาเรียน
                    </router-link>
                  </li>
                </ul>
              </div>
            </template>
            <router-view name="descendant" />
          </sdCards>
        </AddUser>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { AddUser } from './style';
import { Main } from '../../styled';
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const AddNew = {
  name: 'AddNew',
  components: { Main, AddUser },
  props: {
    schoolYearId: Number,
    classroomId: Number,
    studentId: Number,
  },
  setup(props) {
    const { state, getters } = useStore();
    const { matched } = useRoute();
    const classroomId = props.classroomId;
    const studentId = props.studentId;
    const schoolYearId = props.schoolYearId;
    const schoolYear = computed(() => state.schoolYear.selected);
    const classroom = computed(() => getters.getClassroomById(props.classroomId));
    const student = computed(() => getters.getStudentById(props.studentId));
    const routes = computed(() => {
      return [
        {
          path: '',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: `/classroom`,
          breadcrumbName: 'ห้องเรียนทั้งหมด',
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classrooms/${props.classroomId}`,
          breadcrumbName: classroom.value.title,
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classroom/${props.classroomId}/student/${student.value.id}/info`,
          breadcrumbName: `ข้อมูล ${student.value.firstname} ${student.value.lastname}`,
        },
      ];
    });

    onMounted(async () => {
      console.log('onMounted');
    });

    return {
      matched,
      classroomId,
      studentId,
      schoolYearId,
      routes,
    };
  },
};

export default AddNew;
</script>
