<template>
  <CardToolbox>
    <sdBreadcrumb :routes="routes" />
    <sdPageHeader title="เพิ่มนักเรียน">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="warning" key="1">
          <router-link :to="`/schoolYears/${schoolYearId}/classrooms/${classroomId}/`"
            >กลับไปหน้าห้องเรียนของฉัน</router-link
          >
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <AddUser>
          <sdCards>
            <template #title>
              <div class="card-nav">
                <ul>
                  <li>
                    <router-link :to="``">
                      <sdFeatherIcons type="user" size="14" />
                      ข้อมูลนักเรียน
                    </router-link>
                  </li>
                </ul>
              </div>
            </template>
            <router-view name="descendant" />
          </sdCards>
        </AddUser>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { AddUser } from './style';
import { Main } from '../../styled';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';

const AddNew = {
  name: 'AddNew',
  components: { Main, AddUser },
  props: {
    schoolYearId: Number,
    classroomId: Number,
  },
  setup(props) {
    const { state, getters } = useStore();
    const { matched } = useRoute();
    const classroomId = props.classroomId;
    const schoolYearId = props.schoolYearId;
    const schoolYear = computed(() => state.schoolYear.selected);
    const classroom = computed(() => getters.getClassroomById(props.classroomId));

    const routes = computed(() => {
      return [
        {
          path: '',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: `/classroom`,
          breadcrumbName: 'ห้องเรียนทั้งหมด',
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classrooms/${props.classroomId}`,
          breadcrumbName: classroom.value.title,
        },
        {
          path: `/classroom/${props.classroomId}/student/create/info`,
          breadcrumbName: 'สร้างนักเรียนใหม่',
        },
      ];
    });

    return {
      matched,
      classroomId,
      schoolYearId,
      routes,
    };
  },
};

export default AddNew;
</script>
